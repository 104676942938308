import React, { PropsWithChildren, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { twMerge } from 'tailwind-merge'

interface ModalProps {
  onClose: () => void
  className?: string
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  children,
  onClose,
  className,
}) => {
  // disallow scrolling after modal is shown
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [])

  return ReactDOM.createPortal(
    <div
      className={twMerge(
        'text-black fixed inset-0 z-20 bg-black/[0.75]',
        className,
      )}
      onClick={onClose}
    >
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </div>,
    document.body,
  )
}
