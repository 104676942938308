import { postSearchAnalyticsData, SearchResultFragment } from '@/api'
import { AutocompleteItem } from '@/components/autocomplete'

interface ClickEventProps {
  searchQuery: string
  searchResults: SearchResultFragment[]
  searchTimestamp: number
  resultsTimestamp: number
  clickedItem: AutocompleteItem
}

export const clickEvent = ({
  searchQuery,
  searchResults,
  searchTimestamp,
  resultsTimestamp,
  clickedItem,
}: ClickEventProps) => {
  const clickTimestamp = Date.now()

  const indexOfClickedResult = searchResults.findIndex(
    (result) => result.uri === clickedItem.value,
  )

  if (indexOfClickedResult === -1) {
    console.error('This never happens')
  }

  const clickedResult = searchResults[indexOfClickedResult]

  const resultBefore =
    indexOfClickedResult > 0
      ? { ...searchResults[indexOfClickedResult - 1], serp_position: undefined }
      : undefined

  const resultAfter =
    indexOfClickedResult < searchResults.length - 1
      ? { ...searchResults[indexOfClickedResult + 1], serp_position: undefined }
      : undefined

  postSearchAnalyticsData({
    event: 'click',
    click: {
      clicked_at: clickTimestamp,
      result: clickedResult,
      result_before: resultBefore,
      result_after: resultAfter,
    },
    offset: clickTimestamp - resultsTimestamp,
    query: {
      current_value: searchQuery,
      entered_at: searchTimestamp,
    },
    serp: {
      displayed_at: resultsTimestamp,
      results: searchResults,
      search_query: searchQuery,
    },
  })
}
