'use client'

import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'

import { Box } from '@/components/box'
import { IconButton } from '@/components/icon-button'
import { SearchIcon, UserIcon } from '@/components/icons/svg'
import { Popup } from '@/components/popup'
import { Text } from '@/components/text'
import { useAuthContext } from '@/providers'
import {
  HeaderAccountPopupMenu,
  HeaderCartMenu,
  HeaderCartButton,
} from './controls'
import { CustomerPopupMenuItems, GuestPopupMenuItems } from './controls'
import { useHeaderContext } from './header-context'
import { setCookie } from '@/common/utils/cookie-utils'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'

export interface HeaderControlsDesktopProps {
  className?: string
}

export const DesktopHeaderControls = ({
  className,
}: HeaderControlsDesktopProps) => {
  const t = useTranslations('Header')
  const { customerData } = useAuthContext()
  const { state, actions } = useHeaderContext()

  const customerName = [customerData?.firstname, customerData?.lastname]
    .filter((text) => !!text)
    .join(' ')

  const isLoggedIn = !!customerName

  return (
    <Box className={className}>
      <div className="flex items-center">
        <IconButton
          onClick={() => actions.setIsMobileSearch(!state.isMobileSearch)}
          variant="ghost"
          className="md:hidden"
          size="sm"
          aria-label={t('search.placeholder')}
        >
          <SearchIcon className="w-6 h-6 md:h-7 md:w-7" />
        </IconButton>
        <Popup
          openOnHover
          content={
            <HeaderAccountPopupMenu
              items={
                customerName ? (
                  <CustomerPopupMenuItems />
                ) : (
                  <GuestPopupMenuItems />
                )
              }
            />
          }
        >
          {({ onClose }) => (
            <div className="hidden md:flex justify-center items-center">
              {isLoggedIn && (
                <Text
                  size="sm"
                  align="right"
                  color="active"
                  className="self-center"
                >
                  {customerName}
                </Text>
              )}
              <Link
                data-test={selectors.common.header.login}
                className="cursor-pointer text-black flex h-full w-10 px-2 items-center hover:bg-transparent pr-[1px]"
                href="/customer/account/login"
                aria-label={t('cart')}
                onClick={(event) => {
                  if (
                    process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ||
                    process.env.NEXT_PUBLIC_ENVIRONMENT === 'branch'
                  ) {
                    event.preventDefault()
                    event.stopPropagation()
                    const token = prompt('Enter token')

                    if (token) {
                      setCookie('customerToken', token)
                    }
                  }

                  onClose()
                }}
              >
                <UserIcon
                  className={twMerge(
                    `h-6 w-6`,
                    isLoggedIn ? 'fill-current text-secondary' : '',
                  )}
                />
              </Link>
            </div>
          )}
        </Popup>
        <>
          <Popup
            className="hidden md:block"
            openOnHover
            content={<HeaderCartMenu />}
          >
            {() => <HeaderCartButton href="/checkout/cart" />}
          </Popup>
          <div className="md:hidden">
            <HeaderCartButton href="/checkout/cart" />
          </div>
        </>
      </div>
    </Box>
  )
}
