'use client'

import { PropsWithChildren, ReactNode, useState } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import { Box } from '../box'

interface TooltipProps {
  text: string | ReactNode
  direction: 'right' | 'left' | 'bottom' | 'top'
}

export const Tooltip = ({
  children,
  text,
  direction = 'left',
}: PropsWithChildren<TooltipProps>) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <div
      className="relative inline-block bg-white"
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      {children}
      {showTooltip && (
        <div className={twMerge(variants({ direction }))}>
          <div className="relative">
            <Box className={`${twMerge(triangleVariants({ direction }))}`}>
              {text}
            </Box>
          </div>
        </div>
      )}
    </div>
  )
}

const variants = cva(['absolute', 'ml-2', 'bg-white'], {
  variants: {
    direction: {
      top: ['bottom-full', 'left-1/2', 'transform', '-translate-x-1/2'],
      bottom: ['top-full', 'left-1/2', 'transform', '-translate-x-1/2'],
      left: ['top-1/2', 'right-full', 'transform', '-translate-y-1/2'],
      right: ['top-1/2', 'left-full', 'transform', '-translate-y-1/2'],
    },
  },
})

const triangleVariants = cva(
  [
    'inline-block',
    'relative border',
    'border-tooltip-border',
    'no-underline',
    'px-3',
    'py-4',

    'before:content-[""]',
    'after:content-[""]',
    'before:block',
    'after:block',
    'before:absolute',
    'after:absolute',

    'before:w-0',
    'before:h-0',
    'before:border-[6px]',
    'before:border-solid',
    'before:border-transparent',

    'after:border-[5px]',
    'after:border-transparent',
    'after:border-solid',
  ],
  {
    variants: {
      direction: {
        top: [
          'before:-translate-x-2/4',
          'after:-translate-x-2/4',
          'before:left-2/4',
          'after:left-2/4',

          'before:border-t-tooltip-border',
          'before:top-full',

          'after:border-t-white',
          'after:top-[calc(100%)]',
        ],
        bottom: [
          'before:-translate-x-2/4',
          'after:-translate-x-2/4',
          'before:left-2/4',
          'after:left-2/4',

          'before:border-b-tooltip-border',
          'before:bottom-full',

          'after:border-b-white',
          'after:bottom-[calc(100%)]',
        ],
        left: [
          'before:-translate-y-2/4',
          'after:-translate-y-2/4',
          'before:top-2/4',
          'after:top-2/4',

          'before:border-l-tooltip-border',
          'before:left-full',

          'after:border-l-white',
          'after:left-[calc(100%)]',
        ],
        right: [
          'before:-translate-y-2/4',
          'after:-translate-y-2/4',
          'before:top-2/4',
          'after:top-2/4',

          'before:border-r-tooltip-border',
          'before:right-full',

          'after:border-r-white',
          'after:right-[calc(100%)]',
        ],
      },
    },
  },
)
