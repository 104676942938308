import React, { FC, HTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import { Link } from '../link/link'

interface AnchorProps extends HTMLAttributes<HTMLAnchorElement> {
  variant?: 'link' | 'ghost' | 'blank' | 'underline'
  href?: string
  target?: string
  as?: any
  fullWidth?: boolean
}

const variants = cva(['cursor-pointer', 'text-black'], {
  variants: {
    variant: {
      link: ['hover:text-secondary'],
      ghost: [
        'flex',
        'h-12',
        'px-2',
        'w-fit',
        'items-center',
        'hover:bg-grey-100',
      ],
      blank: ['no-underline', 'hover:no-underline'],
      underline: ['underline'],
    },
    fullWidth: {
      true: ['w-full'],
    },
  },

  defaultVariants: {
    variant: 'link',
  },
})

/**
 * @deprecated use components/link component instead
 */
export const Anchor: FC<AnchorProps> = (props) => {
  const {
    className,
    fullWidth,
    variant,
    children,
    as: Component = Link,
    ...rest
  } = props

  return (
    <Component
      {...rest}
      className={twMerge(variants({ variant, fullWidth, className }))}
    >
      {children}
    </Component>
  )
}
