'use client'

import { useMemo } from 'react'

import { ArrowSimpleLeftIcon } from '@/components/icons/svg'
import { Popup } from '@/components/popup'
import { StoreNavigator } from '@/components/store-navigator'
import { STORE_CODE_RECORD } from '@/common/constants'
import { Domain, StoreCodeType, StoreData } from '@/common/types'
import { CountryFlag } from '@/components/icons/flags'
import { Link } from '@/components/link/link'
import { STORE_CODES } from '@/common/utils/get-store-code'
import { getBaseUrl } from '@/common/utils'
import { CookieKeys, setCookie } from '@/common/utils/cookie-utils'
import { getUrl } from '@/common/utils/url-utils'

export type Store = StoreData & {
  url: string
}

const DOT_COM_STORES = [
  STORE_CODE_RECORD.gymbeamsk,
  STORE_CODE_RECORD.gymbeamcz,
  STORE_CODE_RECORD.gymbeamhu,
  STORE_CODE_RECORD.gymbeamro,
  STORE_CODE_RECORD.gymbeamde,
  STORE_CODE_RECORD.gymbeamhr,
  STORE_CODE_RECORD.gymbeambg,
  STORE_CODE_RECORD.gymbeampl,
  STORE_CODE_RECORD.gymbeamsi,
  STORE_CODE_RECORD.gymbeamba,
  STORE_CODE_RECORD.ua,
  STORE_CODE_RECORD.gymbeamrs,
  STORE_CODE_RECORD.gymbeamgr,
  STORE_CODE_RECORD.gymbeamit,
  STORE_CODE_RECORD.gymbeamcom,
]

const DOT_UA_STORES = [
  {
    ...STORE_CODE_RECORD.ru,
    label: 'Pосійська',
  },
  {
    ...STORE_CODE_RECORD.ua,
    label: 'Украина',
  },
]

type LanguageSwitcherProps = {
  storeCode: string
  alternativeLinks?: Record<StoreCodeType, string> | {}
}

export const LanguageSwitcher = ({
  storeCode,
  alternativeLinks,
}: LanguageSwitcherProps) => {
  const isGeneralStore = storeCode === STORE_CODES[Domain.COM]

  const stores = useMemo(() => {
    const stores = isGeneralStore ? DOT_COM_STORES : DOT_UA_STORES

    return stores.map((website) => {
      const base = getBaseUrl(website.storeCode, website.domain)
      const url = getUrl(base, alternativeLinks?.[website.storeCode])

      return {
        ...website,
        url,
      }
    })
  }, [isGeneralStore, alternativeLinks])

  const isLanguageSwitcher = useMemo(
    () =>
      [
        STORE_CODES[Domain.COM],
        STORE_CODES[Domain.UA],
        STORE_CODES[Domain.RU],
      ].some((code) => storeCode === code),
    [storeCode],
  )

  const currentStore = stores.find((website) => storeCode === website.storeCode)

  return (
    <>
      {isGeneralStore && (
        <StoreNavigator stores={stores} currentStore={currentStore} />
      )}
      {isLanguageSwitcher && (
        <div className="bg-primary text-white min-w-full">
          <div className="container max-w-[940px] mx-auto flex justify-end">
            <Popup
              content={
                <div className="text-black p-2.5">
                  {stores?.map((store) => {
                    return (
                      store.storeCode !== currentStore?.storeCode && (
                        <Link
                          key={store.storeCode}
                          className="group flex min-w-max p-0.5 hover:text-secondary items-center cursor-pointer"
                          keepUrl
                          href={store.url}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()

                            setCookie(CookieKeys.STORE_CODE, store.storeCode)
                            history.pushState({}, '', store.url)
                            window.location.reload()
                          }}
                        >
                          <mark className="flex flex-wrap content-around justify-around w-5 h-5 bg-white border-2 border-black rounded-full mr-4">
                            <em className="w-2 h-2 rounded-full group-hover:bg-secondary transition duration-500" />
                          </mark>
                          <CountryFlag
                            icon={store.domain}
                            className="w-7 h-auto"
                          />
                          <span className="pl-3 text-sm">{store.label}</span>
                        </Link>
                      )
                    )
                  })}
                </div>
              }
            >
              {({ isOpen }) => (
                <strong className="inline-flex items-center cursor-pointer p-4 px-1 uppercase">
                  {currentStore?.language ?? currentStore?.lang}{' '}
                  <ArrowSimpleLeftIcon
                    className="inline-flex fill-current ml-1 w-4 h-4 rotate-90 aria-hidden:-rotate-90"
                    aria-hidden={!isOpen}
                  />
                </strong>
              )}
            </Popup>
          </div>
        </div>
      )}
    </>
  )
}
