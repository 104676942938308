'use client'

import { useEffect, useState } from 'react'

import type { ChangeEvent } from 'react'
import { Anchor } from '../anchor'
import { Box } from '../box'
import { Button } from '../button'
import { CloseIcon } from '../icons/svg'
import { Modal } from '../obsolete-modal'
import { Select } from '../select'
import { Text } from '../text'
import { useGeoLocation } from './use-geo-location'
import { Link } from '../link/link'
import type { Store } from '../header/components/language-switcher'
import { CookieKeys, setCookie } from '@/common/utils/cookie-utils'

export const GB_GEO_LOCATION = 'gb-geo-location'
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000

type StoreNavigatorProps = {
  stores: Store[]
  currentStore?: Store
}

export const StoreNavigator = ({
  stores,
  currentStore,
}: StoreNavigatorProps) => {
  const [selectedStore, setSelectedStore] = useState(currentStore)
  const [isModalShown, setIsModalShown] = useState(false)

  const closeModalHandler = () => {
    localStorage.setItem(GB_GEO_LOCATION, String(Date.now() + ONE_DAY_IN_MS))
    setIsModalShown(false)
  }

  const { geoLocationData, recommendedStoreCode } = useGeoLocation({
    stores,
    setIsModalShown,
  })

  const handleSelectStore = (e: ChangeEvent<HTMLSelectElement>) => {
    const storeCode = e.target.value
    const store = stores.find((store) => store.storeCode === storeCode)

    store && setSelectedStore(store)
  }

  useEffect(() => {
    if (!recommendedStoreCode) {
      return
    }

    const recommendedStore = stores.find(
      (store) => store.storeCode === recommendedStoreCode,
    )

    recommendedStore && setSelectedStore(recommendedStore)
  }, [stores, recommendedStoreCode])

  return isModalShown ? (
    <Modal onClose={closeModalHandler}>
      <Box className="fixed top-[50px] left-1/2 translate-x-[-50%] bg-white shadow-[0 0 12px rgba(0, 0, 0, 0.35)] w-11/12 md:w-[420px] pt-5 p-8 flex-col animate-slideDown z-20">
        <Box className="modal-title justify-center mb-5">
          <Text size="lg" className="text-md">
            {geoLocationData?.description}
          </Text>
        </Box>
        <Box className="modal-content justify-center">
          <div className="w-full">
            <Select
              onChange={handleSelectStore}
              value={selectedStore?.storeCode}
              className="flex-grow text-sm w-full"
            >
              {stores?.map((store) => {
                return (
                  store?.storeCode !== currentStore?.storeCode && (
                    <option key={store.storeCode} value={store.storeCode}>
                      {store.label}
                    </option>
                  )
                )
              })}
            </Select>
            <Link
              keepUrl
              href={selectedStore ? selectedStore?.url : ''}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                if (selectedStore) {
                  setCookie(CookieKeys.STORE_CODE, selectedStore.storeCode)
                  history.pushState({}, '', selectedStore.url)
                  window.location.reload()
                }
              }}
            >
              <Button
                disabled={!selectedStore?.url}
                size="base"
                variant="secondary"
                className="w-full mt-3"
              >
                <span className="text-white font-bold">
                  {geoLocationData?.redirect_button_title}
                </span>
              </Button>
            </Link>
          </div>
        </Box>
        <footer className="flex justify-center pt-7">
          <Anchor as="button" variant="underline" onClick={closeModalHandler}>
            {geoLocationData?.stay_button_title}
          </Anchor>
        </footer>
        <Link
          keepUrl
          href="/"
          className="absolute top-3 right-5"
          onClick={(e) => {
            e.preventDefault()
            closeModalHandler()
          }}
        >
          <CloseIcon />
        </Link>
      </Box>
    </Modal>
  ) : (
    <div />
  )
}
